import React from "react";
import Img from "gatsby-image";
import { graphql, useStaticQuery, Link } from "gatsby";
import SEO from "../components/MetaSeo";
import MiniHero from "../components/MiniHero";
import Layout from "../components/layout";
import WhatsappButton from "../components/WhatsappButton";

const QuemSomos = () => {
  const title = "Quem Somos";
  const slug = "quem-somos";

  const { file } = useStaticQuery(graphql`
    query QuemSomosQuery {
      file(name: { eq: "logo" }) {
        childImageSharp {
          # Specify a fixed image and fragment.
          # The default width is 400 pixels
          fluid(quality: 100, maxWidth: 1800) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title={title} pathname={slug} />
      <MiniHero name={title} />

      <section className="text-gray-700 body-font bg-blue-100">
        <div className="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <Img
            className="lg:w-2/6 md:w-3/6 w-5/6 mb-10 object-cover object-center rounded"
            alt="hero"
            fluid={file.childImageSharp.fluid}
          />
          <div className="text-left lg:w-2/3 w-full">
            <h1 className="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              Quem Somos
            </h1>
            <p className="mb-8 leading-relaxed">
              Jovens impactados pela experiência do intercâmbio, em 2012,
              decidiram transformar o Brasil através da educação internacional,
              para garantir que outras pessoas, além de sonharem, sejam capazes
              de realizar e vivenciar uma imersão no exterior. Para isso ser
              possível, pessoas que compartilham do mesmo propósito, uniram-se
              formando uma equipe capaz de garantir aos sonhadores a experiência
              única de vivenciar uma nova cultura, aprimorar uma nova língua,
              desenvolver habilidades que os levem para qualquer lugar do mundo,
              além de estudar nas melhores instituições de ensino superior que
              vão qualificar para o mercado de trabalho internacional. E assim
              nasceram os Dreamers, que apesar das dificuldades, não se
              acomodaram e continuaram crescendo para além de colaboradores e
              estudantes. Os Dreamers são uma comunidade de sonhadores inquietos
              que aspiram mudança e desejam viver oportunidades enriquecedoras
              para um desenvolvimento pessoal e profissional mundo afora.
            </p>
            <iframe
              className="mb-8"
              width="100%"
              height="515"
              src="https://www.youtube.com/embed/B5uWP5NqWpg"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            ></iframe>
          </div>
        </div>
      </section>

      {/* Nosso Negócio */}
      <section className="text-gray-700 body-font bg-red-100">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h2 className="sm:text-3xl text-2xl font-medium title-font text-gray-900">
              Nosso Negócio
            </h2>
          </div>
          <div className="flex flex-wrap -m-4">
            <div className="p-4">
              <div className="flex rounded-lg h-full bg-red-200 p-8 flex-col">
                <div className="flex-grow">
                  <p className="leading-relaxed text-base">
                    Reconhecemos a importância de um intercâmbio cultural e o
                    aprendizado de línguas em sua profundidade. Acreditamos que
                    experiências como estas resultam positivamente não apenas em
                    um indivíduo por si só, mas em um contexto mais amplo de sua
                    atuação na sociedade. Nós não apenas realizamos sonhos, mas
                    temos como missão sermos um elo facilitador no processo de
                    formação de cidadãos mais conscientes para o nosso planeta.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Missão, Visão, Valores */}
      <section className="text-gray-700 body-font bg-blue-100">
        <div className="container px-5 py-24 mx-auto">
          <div className="flex flex-col text-center w-full mb-20">
            <h2 className="sm:text-3xl text-2xl font-medium title-font text-gray-900">
              Missão, Visão, Valores
            </h2>
          </div>
          <div className="flex flex-wrap -m-4">
            <div className="p-4 md:w-1/3">
              <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="flex items-center mb-3">
                  <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="2"
                      className="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 12h-4l-3 9L9 3l-3 9H2"></path>
                    </svg>
                  </div>
                  <h2 className="text-gray-900 text-lg title-font font-medium">
                    Missão
                  </h2>
                </div>
                <div className="flex-grow">
                  <p className="leading-relaxed text-base">
                    Transformar o Brasil através da internacionalização da
                    educação, que é a principal ferramenta na construção de um
                    processo evolutivo e sustentável para nossa sociedade.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-1/3">
              <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="flex items-center mb-3">
                  <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                    <svg
                      className="w-5 h-5"
                      stroke="currentColor"
                      strokeWidth="2"
                      fill="none"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      viewBox="0 0 24 24"
                    >
                      <path d="M1 12s4-8 11-8 11 8 11 8-4 8-11 8-11-8-11-8z"></path>
                      <circle cx="12" cy="12" r="3"></circle>
                    </svg>
                  </div>
                  <h2 className="text-gray-900 text-lg title-font font-medium">
                    Visão
                  </h2>
                </div>
                <div className="flex-grow">
                  <p className="leading-relaxed text-base">
                    Ser a principal referência de educação internacional no
                    Brasil.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-4 md:w-1/3">
              <div className="flex rounded-lg h-full bg-gray-100 p-8 flex-col">
                <div className="flex items-center mb-3">
                  <div className="w-8 h-8 mr-3 inline-flex items-center justify-center rounded-full bg-indigo-500 text-white flex-shrink-0">
                    <svg
                      fill="none"
                      stroke="currentColor"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      className="w-5 h-5"
                      viewBox="0 0 24 24"
                    >
                      <path d="M22 11.08V12a10 10 0 11-5.93-9.14"></path>
                      <path d="M22 4L12 14.01l-3-3"></path>
                    </svg>
                  </div>
                  <h2 className="text-gray-900 text-lg title-font font-medium">
                    Valores
                  </h2>
                </div>
                <div className="flex-grow">
                  <p className="leading-relaxed text-base">
                    <ul>
                      <li>EDUCAÇÃO É SOLUÇÃO</li>
                      <li>REALIZAR SONHOS</li>
                      <li>FOCO DO CLIENTE</li>
                      <li>EXCELÊNCIA NO ATENDIMENTO</li>
                    </ul>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="text-gray-700 body-font bg-green-100">
        <div className="container flex flex-wrap px-5 py-24 mx-auto items-center">
          <div className="md:w-1/2 md:pr-12 md:py-8 md:border-r md:border-b-0 mb-10 md:mb-0 pb-10 border-b border-gray-300">
            <h1 className="sm:text-3xl text-2xl font-medium title-font mb-2 text-gray-900">
              Seja um Dreamer
            </h1>
            <p className="leading-relaxed text-base">
              Deseja trabalhar conosco? Mande seu currículo pra gente no e conta
              um pouco mais sobre você. Boa Sorte! 😉
            </p>
            <a
              href="mailto:rh@dreamsintercambios.com.br"
              className="text-indigo-500 inline-flex items-center mt-4"
            >
              <svg
                fill="none"
                stroke="currentColor"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                className="w-4 h-4 ml-2"
                viewBox="0 0 24 24"
              >
                <path d="M5 12h14M12 5l7 7-7 7"></path>
              </svg>
              rh@dreamsintercambios.com.br
            </a>
          </div>
          <div className="flex flex-col md:w-1/2 md:pl-12">
            <nav className="flex flex-wrap list-none -mb-1">
              <li className="mb-1 w-full">
                <a className="text-gray-600 hover:text-gray-800">
                  Tem um perfil inovador e desruptivo?
                </a>
              </li>
              <li className="mb-1 w-full">
                <a className="text-gray-600 hover:text-gray-800">
                  Tem um mindset positivo orientado para solução de problemas?
                </a>
              </li>
              <li className="mb-1 w-full">
                <a className="text-gray-600 hover:text-gray-800">
                  Gosta de pessoas?
                </a>
              </li>
              <li className="mb-1 w-full">
                <a className="text-gray-600 hover:text-gray-800">
                  Gosta de viagem?
                </a>
              </li>
              <li className="mb-1 w-full">
                <a className="text-gray-600 hover:text-gray-800">
                  Já fez um intercâmbio?
                </a>
              </li>
              <li className="mb-1 w-full">
                <a className="text-gray-600 hover:text-gray-800">
                  Consegue se comunicar em inglês?
                </a>
              </li>
            </nav>
          </div>
        </div>
      </section>

      <WhatsappButton />
    </Layout>
  );
};

export default QuemSomos;
